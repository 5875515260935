import { useNuxtApp } from "#imports";
import { type $Fetch } from "ofetch";

export const useApp = () => {
  const nuxtApp = useNuxtApp();
  const localeRoute = useLocaleRoute();

  const errorHandler = (e: any) => {
    console.log("error handler", e);
  };
  const navigateLocale = (...x: Parameters<typeof localeRoute>) => navigateTo(localeRoute(...x));

  return {
    siteFetch: nuxtApp.$siteFetch as $Fetch,
    errorHandler,
    navigateLocale,
  };
};
