import { default as indexMScMo3Bi82Meta } from "/opt/apps/web/pages/index.vue?macro=true";
import { default as indexOJthvvQICIMeta } from "/opt/apps/web/pages/[place]/[parking]/index.vue?macro=true";
import { default as reservation78rD0Na45AMeta } from "/opt/apps/web/pages/[place]/[parking]/reservation.vue?macro=true";
import { default as optionsNbWvMnAJvdMeta } from "/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue?macro=true";
import { default as index4Js8ZpGldgMeta } from "/opt/apps/web/pages/[place]/index.vue?macro=true";
import { default as _91slug_93O6AhxQoAhnMeta } from "/opt/apps/web/pages/blog/[slug].vue?macro=true";
import { default as index98QeOwUcAaMeta } from "/opt/apps/web/pages/blog/index.vue?macro=true";
import { default as _91page_932UxFcpIw7PMeta } from "/opt/apps/web/pages/blog/pages/[page].vue?macro=true";
import { default as billingKbwuMOVndIMeta } from "/opt/apps/web/pages/bookings/[booking]/edit/billing.vue?macro=true";
import { default as detailsqxjFM32W0KMeta } from "/opt/apps/web/pages/bookings/[booking]/edit/details.vue?macro=true";
import { default as indexM4QM0mzyG0Meta } from "/opt/apps/web/pages/bookings/[booking]/index.vue?macro=true";
import { default as manage36CTewKD4FMeta } from "/opt/apps/web/pages/bookings/manage.vue?macro=true";
import { default as remindNm1DafxZJqMeta } from "/opt/apps/web/pages/bookings/remind.vue?macro=true";
import { default as contactvvuKCl6KMqMeta } from "/opt/apps/web/pages/contact.vue?macro=true";
import { default as confirmnICy3oYLiTMeta } from "/opt/apps/web/pages/customer/auth/confirm.vue?macro=true";
import { default as login91h2PlO8lmMeta } from "/opt/apps/web/pages/customer/auth/login.vue?macro=true";
import { default as registerunqX1iDHCFMeta } from "/opt/apps/web/pages/customer/auth/register.vue?macro=true";
import { default as remindMCWxjawFBZMeta } from "/opt/apps/web/pages/customer/auth/remind.vue?macro=true";
import { default as index3gbo3Mz4syMeta } from "/opt/apps/web/pages/customer/bookings/index.vue?macro=true";
import { default as index8US3NjamWtMeta } from "/opt/apps/web/pages/customer/extras/index.vue?macro=true";
import { default as indexkakOF1Vn9KMeta } from "/opt/apps/web/pages/customer/index.vue?macro=true";
import { default as index32ThOmjcByMeta } from "/opt/apps/web/pages/customer/settings/index.vue?macro=true";
import { default as passwordIoSvqLeejtMeta } from "/opt/apps/web/pages/customer/settings/password.vue?macro=true";
import { default as indexXeZ93U622aMeta } from "/opt/apps/web/pages/customer/vehicles/index.vue?macro=true";
import { default as _91category_93bvu4iHBcIqMeta } from "/opt/apps/web/pages/faq/[category].vue?macro=true";
import { default as indexhZWYNULP0yMeta } from "/opt/apps/web/pages/faq/index.vue?macro=true";
import { default as searchDuVVLezbP7Meta } from "/opt/apps/web/pages/faq/search.vue?macro=true";
import { default as gps5yVmzUQYKRMeta } from "/opt/apps/web/pages/gps.vue?macro=true";
import { default as extras_45airclaimsWov3U3KAsLMeta } from "/opt/apps/web/pages/others/extras-airclaims.vue?macro=true";
import { default as extras_45carrentalsJ1CBQehrSIMeta } from "/opt/apps/web/pages/others/extras-carrentals.vue?macro=true";
import { default as extras_45travelinsuranceXpT92s2NIYMeta } from "/opt/apps/web/pages/others/extras-travelinsurance.vue?macro=true";
import { default as howitworksE15LWoqtyOMeta } from "/opt/apps/web/pages/others/howitworks.vue?macro=true";
import { default as howtoselectcOWQYgrDRpMeta } from "/opt/apps/web/pages/others/howtoselect.vue?macro=true";
import { default as rules_45privacy2aML1pbIzVMeta } from "/opt/apps/web/pages/others/rules-privacy.vue?macro=true";
import { default as rules_45termsPzHTewMHRIMeta } from "/opt/apps/web/pages/others/rules-terms.vue?macro=true";
import { default as whywe6d6oRgk7FhMeta } from "/opt/apps/web/pages/others/whywe.vue?macro=true";
import { default as airportsn5yXkl66uzMeta } from "/opt/apps/web/pages/parkings/airports.vue?macro=true";
import { default as stationsJlib5hFFrHMeta } from "/opt/apps/web/pages/parkings/stations.vue?macro=true";
import { default as component_45stubsUQ0AiknVkMeta } from "/opt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubsUQ0AiknVk } from "/opt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "place-parking___en",
    path: "/en/:place()/:parking()/",
    meta: indexOJthvvQICIMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/index.vue")
  },
  {
    name: "place-parking___es",
    path: "/es/:place()/:parking()/",
    meta: indexOJthvvQICIMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/index.vue")
  },
  {
    name: "place-parking-reservation___en",
    path: "/en/:place()/:parking()/reservation/",
    meta: reservation78rD0Na45AMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation.vue")
  },
  {
    name: "place-parking-reservation___es",
    path: "/es/:place()/:parking()/reserva/",
    meta: reservation78rD0Na45AMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation.vue")
  },
  {
    name: "place-parking-reservation2-options___en",
    path: "/en/:place()/:parking()/reservation2/options/",
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue")
  },
  {
    name: "place-parking-reservation2-options___es",
    path: "/es/:place()/:parking()/reservation2/options/",
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue")
  },
  {
    name: "place___en",
    path: "/en/:place()/",
    meta: index4Js8ZpGldgMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/index.vue")
  },
  {
    name: "place___es",
    path: "/es/:place()/",
    meta: index4Js8ZpGldgMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/index.vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()/",
    meta: _91slug_93O6AhxQoAhnMeta || {},
    component: () => import("/opt/apps/web/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()/",
    meta: _91slug_93O6AhxQoAhnMeta || {},
    component: () => import("/opt/apps/web/pages/blog/[slug].vue")
  },
  {
    name: "blog___en",
    path: "/en/blog/",
    meta: index98QeOwUcAaMeta || {},
    component: () => import("/opt/apps/web/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog/",
    meta: index98QeOwUcAaMeta || {},
    component: () => import("/opt/apps/web/pages/blog/index.vue")
  },
  {
    name: "blog-pages-page___en",
    path: "/en/blog/pages/:page()/",
    meta: _91page_932UxFcpIw7PMeta || {},
    component: () => import("/opt/apps/web/pages/blog/pages/[page].vue")
  },
  {
    name: "blog-pages-page___es",
    path: "/es/blog/pages/:page()/",
    meta: _91page_932UxFcpIw7PMeta || {},
    component: () => import("/opt/apps/web/pages/blog/pages/[page].vue")
  },
  {
    name: "bookings-booking-edit-billing___en",
    path: "/en/bookings/:booking()/edit/billing/",
    meta: billingKbwuMOVndIMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/billing.vue")
  },
  {
    name: "bookings-booking-edit-billing___es",
    path: "/es/bookings/:booking()/edit/billing/",
    meta: billingKbwuMOVndIMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/billing.vue")
  },
  {
    name: "bookings-booking-edit-details___en",
    path: "/en/bookings/:booking()/edit/details/",
    meta: detailsqxjFM32W0KMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/details.vue")
  },
  {
    name: "bookings-booking-edit-details___es",
    path: "/es/bookings/:booking()/edit/details/",
    meta: detailsqxjFM32W0KMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/details.vue")
  },
  {
    name: "bookings-booking___en",
    path: "/en/bookings/:booking()/",
    meta: indexM4QM0mzyG0Meta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/index.vue")
  },
  {
    name: "bookings-booking___es",
    path: "/es/bookings/:booking()/",
    meta: indexM4QM0mzyG0Meta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/index.vue")
  },
  {
    name: "bookings-manage___en",
    path: "/en/manage-booking/",
    meta: manage36CTewKD4FMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/manage.vue")
  },
  {
    name: "bookings-manage___es",
    path: "/es/administrar-reservaciones/",
    meta: manage36CTewKD4FMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/manage.vue")
  },
  {
    name: "bookings-remind___en",
    path: "/en/bookings/remind/",
    meta: remindNm1DafxZJqMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/remind.vue")
  },
  {
    name: "bookings-remind___es",
    path: "/es/bookings/remind/",
    meta: remindNm1DafxZJqMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/remind.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact/",
    meta: contactvvuKCl6KMqMeta || {},
    component: () => import("/opt/apps/web/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contacto/",
    meta: contactvvuKCl6KMqMeta || {},
    component: () => import("/opt/apps/web/pages/contact.vue")
  },
  {
    name: "customer-auth-confirm___en",
    path: "/en/customer/auth/confirm/",
    component: () => import("/opt/apps/web/pages/customer/auth/confirm.vue")
  },
  {
    name: "customer-auth-confirm___es",
    path: "/es/customer/auth/confirm/",
    component: () => import("/opt/apps/web/pages/customer/auth/confirm.vue")
  },
  {
    name: "customer-auth-login___en",
    path: "/en/customer/auth/login/",
    meta: login91h2PlO8lmMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/login.vue")
  },
  {
    name: "customer-auth-login___es",
    path: "/es/customer/auth/login/",
    meta: login91h2PlO8lmMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/login.vue")
  },
  {
    name: "customer-auth-register___en",
    path: "/en/customer/auth/register/",
    meta: registerunqX1iDHCFMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/register.vue")
  },
  {
    name: "customer-auth-register___es",
    path: "/es/customer/auth/register/",
    meta: registerunqX1iDHCFMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/register.vue")
  },
  {
    name: "customer-auth-remind___en",
    path: "/en/customer/auth/remind/",
    meta: remindMCWxjawFBZMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/remind.vue")
  },
  {
    name: "customer-auth-remind___es",
    path: "/es/customer/auth/remind/",
    meta: remindMCWxjawFBZMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/remind.vue")
  },
  {
    name: "customer-bookings___en",
    path: "/en/customer/bookings/",
    meta: index3gbo3Mz4syMeta || {},
    component: () => import("/opt/apps/web/pages/customer/bookings/index.vue")
  },
  {
    name: "customer-bookings___es",
    path: "/es/customer/bookings/",
    meta: index3gbo3Mz4syMeta || {},
    component: () => import("/opt/apps/web/pages/customer/bookings/index.vue")
  },
  {
    name: "customer-extras___en",
    path: "/en/customer/extras/",
    meta: index8US3NjamWtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/extras/index.vue")
  },
  {
    name: "customer-extras___es",
    path: "/es/customer/extras/",
    meta: index8US3NjamWtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/extras/index.vue")
  },
  {
    name: "customer___en",
    path: "/en/customer/",
    meta: indexkakOF1Vn9KMeta || {},
    component: () => import("/opt/apps/web/pages/customer/index.vue")
  },
  {
    name: "customer___es",
    path: "/es/customer/",
    meta: indexkakOF1Vn9KMeta || {},
    component: () => import("/opt/apps/web/pages/customer/index.vue")
  },
  {
    name: "customer-settings___en",
    path: "/en/customer/settings/",
    meta: index32ThOmjcByMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/index.vue")
  },
  {
    name: "customer-settings___es",
    path: "/es/customer/settings/",
    meta: index32ThOmjcByMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/index.vue")
  },
  {
    name: "customer-settings-password___en",
    path: "/en/customer/settings/password/",
    meta: passwordIoSvqLeejtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/password.vue")
  },
  {
    name: "customer-settings-password___es",
    path: "/es/customer/settings/password/",
    meta: passwordIoSvqLeejtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/password.vue")
  },
  {
    name: "customer-vehicles___en",
    path: "/en/customer/vehicles/",
    meta: indexXeZ93U622aMeta || {},
    component: () => import("/opt/apps/web/pages/customer/vehicles/index.vue")
  },
  {
    name: "customer-vehicles___es",
    path: "/es/customer/vehicles/",
    meta: indexXeZ93U622aMeta || {},
    component: () => import("/opt/apps/web/pages/customer/vehicles/index.vue")
  },
  {
    name: "faq-category___en",
    path: "/en/airport-carpark-questions/:category()/",
    meta: _91category_93bvu4iHBcIqMeta || {},
    component: () => import("/opt/apps/web/pages/faq/[category].vue")
  },
  {
    name: "faq-category___es",
    path: "/es/preguntas-estacionamiento/:category()/",
    meta: _91category_93bvu4iHBcIqMeta || {},
    component: () => import("/opt/apps/web/pages/faq/[category].vue")
  },
  {
    name: "faq___en",
    path: "/en/airport-carpark-questions/",
    meta: indexhZWYNULP0yMeta || {},
    component: () => import("/opt/apps/web/pages/faq/index.vue")
  },
  {
    name: "faq___es",
    path: "/es/preguntas-estacionamiento/",
    meta: indexhZWYNULP0yMeta || {},
    component: () => import("/opt/apps/web/pages/faq/index.vue")
  },
  {
    name: "faq-search___en",
    path: "/en/faq/search/",
    meta: searchDuVVLezbP7Meta || {},
    component: () => import("/opt/apps/web/pages/faq/search.vue")
  },
  {
    name: "faq-search___es",
    path: "/es/faq/search/",
    meta: searchDuVVLezbP7Meta || {},
    component: () => import("/opt/apps/web/pages/faq/search.vue")
  },
  {
    name: "gps___en",
    path: "/en/gps/",
    meta: gps5yVmzUQYKRMeta || {},
    component: () => import("/opt/apps/web/pages/gps.vue")
  },
  {
    name: "gps___es",
    path: "/es/gps/",
    meta: gps5yVmzUQYKRMeta || {},
    component: () => import("/opt/apps/web/pages/gps.vue")
  },
  {
    name: "index___en",
    path: "/en/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "others-extras-airclaims___en",
    path: "/en/others/extras-airclaims/",
    meta: extras_45airclaimsWov3U3KAsLMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-airclaims.vue")
  },
  {
    name: "others-extras-airclaims___es",
    path: "/es/others/extras-airclaims/",
    meta: extras_45airclaimsWov3U3KAsLMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-airclaims.vue")
  },
  {
    name: "others-extras-carrentals___en",
    path: "/en/car-rentals/",
    meta: extras_45carrentalsJ1CBQehrSIMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-carrentals.vue")
  },
  {
    name: "others-extras-carrentals___es",
    path: "/es/alquiler-de-coches/",
    meta: extras_45carrentalsJ1CBQehrSIMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-carrentals.vue")
  },
  {
    name: "others-extras-travelinsurance___en",
    path: "/en/travel-insurance/",
    meta: extras_45travelinsuranceXpT92s2NIYMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-travelinsurance.vue")
  },
  {
    name: "others-extras-travelinsurance___es",
    path: "/es/seguro-de-viaje/",
    meta: extras_45travelinsuranceXpT92s2NIYMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-travelinsurance.vue")
  },
  {
    name: "others-howitworks___en",
    path: "/en/how-it-works/",
    meta: howitworksE15LWoqtyOMeta || {},
    component: () => import("/opt/apps/web/pages/others/howitworks.vue")
  },
  {
    name: "others-howitworks___es",
    path: "/es/como-funciona/",
    meta: howitworksE15LWoqtyOMeta || {},
    component: () => import("/opt/apps/web/pages/others/howitworks.vue")
  },
  {
    name: "others-howtoselect___en",
    path: "/en/how-to-choose-place-parking/",
    meta: howtoselectcOWQYgrDRpMeta || {},
    component: () => import("/opt/apps/web/pages/others/howtoselect.vue")
  },
  {
    name: "others-howtoselect___es",
    path: "/es/como-elegir-estacionamiento-aeropuerto/",
    meta: howtoselectcOWQYgrDRpMeta || {},
    component: () => import("/opt/apps/web/pages/others/howtoselect.vue")
  },
  {
    name: "others-rules-privacy___en",
    path: "/en/privacy-policy/",
    meta: rules_45privacy2aML1pbIzVMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-privacy.vue")
  },
  {
    name: "others-rules-privacy___es",
    path: "/es/politica-de-privacidad/",
    meta: rules_45privacy2aML1pbIzVMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-privacy.vue")
  },
  {
    name: "others-rules-terms___en",
    path: "/en/rules/",
    meta: rules_45termsPzHTewMHRIMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-terms.vue")
  },
  {
    name: "others-rules-terms___es",
    path: "/es/reglas/",
    meta: rules_45termsPzHTewMHRIMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-terms.vue")
  },
  {
    name: "others-whywe___en",
    path: "/en/why-nextpark/",
    meta: whywe6d6oRgk7FhMeta || {},
    component: () => import("/opt/apps/web/pages/others/whywe.vue")
  },
  {
    name: "others-whywe___es",
    path: "/es/porque-nextpark/",
    meta: whywe6d6oRgk7FhMeta || {},
    component: () => import("/opt/apps/web/pages/others/whywe.vue")
  },
  {
    name: "parkings-airports___en",
    path: "/en/airport-parkings/",
    meta: airportsn5yXkl66uzMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/airports.vue")
  },
  {
    name: "parkings-airports___es",
    path: "/es/estacionamiento-aeropuerto/",
    meta: airportsn5yXkl66uzMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/airports.vue")
  },
  {
    name: "parkings-stations___en",
    path: "/en/parking-station/",
    meta: stationsJlib5hFFrHMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/stations.vue")
  },
  {
    name: "parkings-stations___es",
    path: "/es/estacionamiento-estacion/",
    meta: stationsJlib5hFFrHMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/stations.vue")
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/en-GB-sitemap.xml",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubsUQ0AiknVk
  }
]