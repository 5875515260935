<script setup lang="ts">
import { useGlobalStore } from "./stores/global";

const route = useRoute();

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});

const globalStore = useGlobalStore();

const { locale } = useI18n();

await useAsyncData(async () => {
  await globalStore.init();
  return {};
});

useHead(() => ({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - NextPark` : "NextPark";
  },
  htmlAttrs: {
    dir: i18nHead.value.htmlAttrs!.dir,
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [
    ...(route.meta.noI18n ? [] : i18nHead.value.link || []),    
  ],
  meta: [
    ...(i18nHead.value.meta || []),
  ],
}));

// TODO: remove if locale change refreshes the page.
watch(locale, () => globalStore.init());
</script>

<template>
  <v-app>
    <NuxtLayout>      
      <NuxtPwaAssets />
      <NuxtPage />
    </NuxtLayout>
  </v-app>
</template>
