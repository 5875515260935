import { DatePicker, Calendar } from "v-calendar";
import "../assets/styles/vcalendar.scss";

export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.vueApp.use(DatePicker);

  nuxtApp.vueApp.component('DatePicker', DatePicker)
  nuxtApp.vueApp.component('VCalendar', Calendar)

});
