import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.dev) return;

  const config = useRuntimeConfig();

  nuxtApp.vueApp.use(
    createGtm({
      id: "GTM-KC5PVZD",
      source: config.public.tag + "/np.js",
      defer: true,
      compatibility: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
    })
  );
});
