
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js" /* webpackChunkName: "locale__opt_apps_web_locales_en_js" */), cache: true }],
  "es": [{ key: "../locales/es.js", load: () => import("../locales/es.js" /* webpackChunkName: "locale__opt_apps_web_locales_es_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": true,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "flag": "gb",
      "name": "English",
      "language": "en-GB",
      "files": [
        "/opt/apps/web/locales/en.js"
      ]
    },
    {
      "code": "es",
      "flag": "es",
      "name": "Português",
      "language": "es-ES",
      "files": [
        "/opt/apps/web/locales/es.js"
      ]
    }
  ],
  "defaultLocale": "es",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://nextpark.es",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "parkings/airports": {
      "en": "/airport-parkings",
      "es": "/estacionamiento-aeropuerto"
    },
    "parkings/stations": {
      "en": "/parking-station",
      "es": "/estacionamiento-estacion"
    },
    "contact": {
      "en": "/contact",
      "es": "/contacto"
    },
    "others/howtoselect": {
      "en": "/how-to-choose-place-parking",
      "es": "/como-elegir-estacionamiento-aeropuerto"
    },
    "others/whywe": {
      "en": "/why-nextpark",
      "es": "/porque-nextpark"
    },
    "others/extras-airclaim": {
      "en": "/airline-compensation",
      "es": "/compensacion-de-la-aerolinea"
    },
    "others/extras-travelinsurance": {
      "en": "/travel-insurance",
      "es": "/seguro-de-viaje"
    },
    "others/extras-carrentals": {
      "en": "/car-rentals",
      "es": "/alquiler-de-coches"
    },
    "others/rules-terms": {
      "en": "/rules",
      "es": "/reglas"
    },
    "others/rules-privacy": {
      "en": "/privacy-policy",
      "es": "/politica-de-privacidad"
    },
    "others/howitworks": {
      "en": "/how-it-works",
      "es": "/como-funciona"
    },
    "faq/index": {
      "en": "/airport-carpark-questions",
      "es": "/preguntas-estacionamiento"
    },
    "faq/[category]": {
      "en": "/airport-carpark-questions/[category]",
      "es": "/preguntas-estacionamiento/[category]"
    },
    "bookings/manage": {
      "en": "/manage-booking",
      "es": "/administrar-reservaciones"
    },
    "bookings/booking": {
      "en": "/manage-booking/my-booking",
      "es": "/administrar-reservaciones/my-reservacion"
    },
    "confirmation": {
      "en": "/confirmation",
      "es": "/confirmacion"
    },
    "[place]/[parking]/reservation": {
      "en": "/[place]/[parking]/reservation",
      "es": "/[place]/[parking]/reserva"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "flag": "gb",
    "name": "English",
    "language": "en-GB",
    "files": [
      {
        "path": "/opt/apps/web/locales/en.js"
      }
    ]
  },
  {
    "code": "es",
    "flag": "es",
    "name": "Português",
    "language": "es-ES",
    "files": [
      {
        "path": "/opt/apps/web/locales/es.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
